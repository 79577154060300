import React from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '~/components/ui/hover-card';
import SkeletonCard from '~/components/skeletonCard';
import { ContainerWrapper } from '../../layout/container';
import ThemeTrendChart from '../components/themeTrendChart';
import TopVoicesByTheme from '../components/topVoicesByTheme';
import ThemeBySectorChart from '../components/themeBySectorChart';
import RecentContentByTheme from '../components/recentContentByTheme';
import ExecutiveBioAndThumbnail from '../components/executiveBioAndThumbnail';
import ExecutiveThemeComparison from '../components/executiveThemeComparison';
import RelatedExecutives from '../components/relatedExecutives';

import { useTeamConfigs } from '../api/getTeamConfigs';

const Overview = (): JSX.Element => {
    const { configs, isLoading: isConfigsLoading } = useTeamConfigs();
    if (isConfigsLoading) {
        return <SkeletonCard />;
    }
    if (!configs) {
        return <></>;
    }
    return (
        <ContainerWrapper className="px-3 background-offset xl:px-0">
            <div className="flex flex-col mx-5 my-5 xl:flex-row xl:space-x-5 xl:container xl:mx-auto 2xl:px-5 2xl:mt-10 2xl:space-x-10">
                <div className="w-full">
                    <h1 className="mb-5">Featured Executive</h1>
                    <ExecutiveBioAndThumbnail />
                    <h2 className="text-xl mt-10 mb-5">
                        Top Theme Comparison{' '}
                        <HoverCard>
                            <HoverCardTrigger className="text-sm ml-1 text-gray-500">
                                <i className="far fa-info-circle hover:text-cometrics"></i>
                            </HoverCardTrigger>
                            <HoverCardContent className="w-96 border-cometrics">
                                <p>
                                    This section showcases the top 5 themes most frequently mentioned by the Executive,
                                    their Company, and the overall sector they operate in.
                                </p>
                            </HoverCardContent>
                        </HoverCard>
                    </h2>
                    <ExecutiveThemeComparison executiveId={configs?.executive?.route} noPadding={false} />
                    <RelatedExecutives />

                    <h1 className="mt-10 mb-5">Featured Theme - {configs?.theme?.name}</h1>
                    <ThemeTrendChart />
                    <div className="mt-5">
                        <ThemeBySectorChart />
                    </div>
                    <TopVoicesByTheme />
                    <RecentContentByTheme />
                </div>
            </div>
        </ContainerWrapper>
    );
};

export default Overview;
