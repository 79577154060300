import React from 'react';

import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import { Card, CardContent, CardDescription, CardHeader } from '~/components/ui/card';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '~/components/ui/chart';
import SkeletonCard from '~/components/skeletonCard';

import { useThemeSectorTrends } from '../api/getThemeCountBySector';
import { useTeamConfigs } from '../api/getTeamConfigs';

const chartConfig = {
    desktop: {
        label: 'Sector',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;
const ThemeBySectorChart = () => {
    const { configs } = useTeamConfigs();
    const { trends, isLoading } = useThemeSectorTrends(configs?.theme?.name);

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!trends) {
        return <></>;
    }
    return (
        <Card className="border-0 shadow-none">
            <CardHeader>
                <CardDescription>Mentions by executives in each sector</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig} className="h-[300px]">
                    <BarChart accessibilityLayer data={trends}>
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="sectorName" tickLine={false} tickMargin={10} axisLine={false} />
                        <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
                        <Bar dataKey="count" fill="var(--color-desktop)" radius={5} />
                    </BarChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
};

export default ThemeBySectorChart;
