import React from 'react';
import { NavLink } from 'react-router-dom';

import SkeletonCard from '~/components/skeletonCard';
import { Card, CardContent, CardDescription } from '~/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

import { useFeaturedExecutive } from '../api/getFeaturedExecutive';
import { useTeamConfigs } from '../api/getTeamConfigs';

const ExecutiveBioAndThumbnail = () => {
    const { configs } = useTeamConfigs();
    const { executive, isLoading } = useFeaturedExecutive(configs?.executive?.route);

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!executive) {
        return <></>;
    }
    return (
        <Card className="border-0 shadow-none">
            <CardContent className="pt-6">
                <div className="flex">
                    <img
                        alt="Executive Thumbnail"
                        src={executive.profile_thumbnail_url}
                        className="bg-white rounded-xl w-full max-w-[150px] object-cover aspect-square"
                    />
                    <div className="ml-5">
                        <NavLink to={`/app/executives/${executive.route}`}>
                            <h2>{executive.name}</h2>
                            <CardDescription>
                                {executive.title} @ {executive.company.name}
                            </CardDescription>
                        </NavLink>
                        {executive.summary ? (
                            <>
                                <p className="pt-3">{executive.summary} </p>
                                <TooltipProvider delayDuration={100}>
                                    <Tooltip>
                                        <TooltipTrigger className="text-black rounded px-2 py-1 text-sm bg-cometrics-100">
                                            <i className="far fa-sparkles text-cometrics mr-2"></i>
                                            Generated by CLAIR
                                        </TooltipTrigger>
                                        <TooltipContent className="w-96 border-cometrics">
                                            <p>
                                                This Executive Summary was created using the latest updates from the
                                                executive&apos;s social media activity, LinkedIn posts, and references
                                                within corporate documents, including press releases, sustainability
                                                reports, company websites, and Analyst Calls.
                                            </p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </>
                        ) : (
                            <p className="pt-3">Biography being curated. Please check back later or contact us</p>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default ExecutiveBioAndThumbnail;
