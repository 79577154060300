import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';

export const getRecentContentByTheme = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useRecentContentByTheme = (themeName: string) => {
    const { data, error, isLoading } = useSWR<any[]>(
        `/api/stats/executive-content-by-theme?theme_name=${encodeURIComponent(themeName)}`,
        getRecentContentByTheme,
    );

    return {
        content: data,
        isLoading,
        isError: error,
    };
};
