import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';

export const getTeamConfigs = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useTeamConfigs = () => {
    const { data, error, isLoading } = useSWR(`/api/account/`, getTeamConfigs);

    return {
        configs: data?.team?.configs,
        isLoading,
        isError: error,
    };
};
