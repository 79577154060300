import useSWR from 'swr';
import ApiClient from '~/utils/apiClient';
import { Executive } from '~/dashboard/executives';

export const getTopVoicesByTheme = async (url: string) => {
    const response = await ApiClient.get(url);
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
};

export const useTopVoicesByTheme = (themeName: string) => {
    const { data, error, isLoading } = useSWR<Executive[]>(
        `/api/stats/executive-top-theme-voices?theme_name=${encodeURIComponent(themeName)}`,
        getTopVoicesByTheme,
    );

    return {
        executives: data,
        isLoading,
        isError: error,
    };
};
