import React from 'react';

import SkeletonCard from '~/components/skeletonCard';
import { Executive } from '~/dashboard/executives';
import ResultCard from '~/dashboard/executives/components/search/resultCard';

import { useRelatedExecutives } from '../../executives/api/getRelatedExecutives';
import { useTeamConfigs } from '../api/getTeamConfigs';

const RelatedExecutives = () => {
    const { configs } = useTeamConfigs();
    const { executives, isLoading } = useRelatedExecutives(configs?.executive?.route);

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!executives) {
        return <></>;
    }

    return (
        <>
            <h2 className="text-xl mt-10 mb-5">Related Executives</h2>
            <div className="flex flex-row justify-between space-x-10">
                {executives.map((exec: Executive) => (
                    <ResultCard hit={exec} key={exec.uuid} />
                ))}
            </div>
        </>
    );
};

export default RelatedExecutives;
